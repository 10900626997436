import logo from "./logo.svg";
import "./App.css";
import FeaturesBlocks from "./components/FeatureBlock";
import Heading from "./components/Heading";
import Link from "./components/Link";
import Button from "./components/Button";
import { FaBook } from "react-icons/fa6";
import { FaSignInAlt } from "react-icons/fa";

function App() {
  return (
    <div className="bg-home bg-cover bg-fixed text-white ">
      <div className="backdrop-blur-md min-h-screen">
        <img
          src="/logo-big-t.png"
          alt="logo"
          className="max-w-[400px] mx-auto pt-10 pb-8"
        />
        <div className="m-auto max-w-xl">
          <p>
            HexCraftは、<Link href="#">CC: Tweaked</Link>や
            <Link href="#">Create</Link>
            などのmodを導入したMinecraftサーバーです。
          </p>
          <p>Minecraftの世界で、プログラミングで遊んでみませんか？</p>
        </div>
        <div className="mx-auto text-center">
          <Button
            href="https://wiki.hexcraft.cc/ja/howto"
            title="サーバーに参加する！"
            description="ログイン手順を確認する"
            icon={<FaSignInAlt />}
          />
          <Button
            href="https://wiki.hexcraft.cc/"
            title="Wikiを見る"
            description="詳しい情報を知る"
            icon={<FaBook />}
            color="bg-sky-600"
          />
        </div>
        <FeaturesBlocks />
        <div className="m-auto text-center text-sm text-gray-300 pb-8 pt-8">
          hexcraft.cc by <Link href="https://twitter.com/palon7">@palon7</Link>
        </div>
      </div>
    </div>
  );
}

export default App;
