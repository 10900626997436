export default function Button({
  title,
  description,
  icon,
  href,
  color = "bg-lime-600",
}) {
  return (
    <div className={`max-w-[350px] rounded-2xl ${color} mx-auto mt-8`}>
      <a href={href} className="transition duration-100 ease-in-out">
        <div className="flex flex-row  items-center px-2 py-4">
          <div className="pl-4 text-[50px]">
            <div className=" text-white">{icon}</div>
          </div>
          <div className="ml-4 text-left ">
            <h4 className="text-xl font-medium leading-5 text-white ">
              {title}
            </h4>
            <p className="text-sm leading-5 mt-1 text-gray-300 ">
              {description}
            </p>
          </div>
        </div>
      </a>
    </div>
  );
}
