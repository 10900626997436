export default function Link({ children, href }) {
  return (
    <a
      href={href}
      className="text-gray-400 hover:text-gray-200 transition duration-100 ease-in-out"
    >
      {children}
    </a>
  );
}
