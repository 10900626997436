import { FaTerminal, FaGear, FaLock } from "react-icons/fa6";
import Heading from "./Heading";

function FeatureItem({ title, description, icon }) {
  return (
    <div className="relative flex flex-col items-center p-6 bg-gray-800 rounded shadow-xl">
      <div className="bg-slate-500 text-white rounded-full w-12 h-12 flex items-center justify-center">
        {icon}
      </div>
      <h4 className="text-xl font-bold leading-snug tracking-tight mb-1 mt-2">
        {title}
      </h4>
      <p className="text-gray-300 text-center text-sm">{description}</p>
    </div>
  );
}

const mods = [
  {
    title: "ComputerCraft",
    description:
      "コンピュータやロボットをプログラミングして、採掘を自動化してみたり、インターネットを再現してみたり……\nその可能性は無限大です。",
    icon: <FaTerminal />,
  },
  {
    title: "Create",
    description:
      "エレベーターや列車、大規模な工場などを作ってみましょう。もちろん、コンピュータから制御することも可能です。",
    icon: <FaGear />,
  },
];

const features = [
  {
    title: "プログラムで遊ぼう",
    description:
      "Luaでプログラムできるコンピュータにロボット、通信モデムや携帯型コンピュータなどが追加されます。がんばればスマートフォンだって作れちゃうかも……？",
    icon: <FaTerminal />,
  },
  {
    title: "荒らし対策も万全",
    description:
      "エリア保護とログ調査・ロールバックができるmodを導入しています。mod環境でもすべてを台無しにされることはありません。",
    icon: <FaLock />,
  },
  {
    title: "インストールも簡単",
    description:
      "もうmodのインストールに苦労する必要はありません。一度ランチャーをインストールしてパックを追加すれば、常に最新のModパックで遊べます。",
    icon: <FaGear />,
  },
];

export default function FeaturesBlocks() {
  return (
    <section className="relative">
      {/* Section background (needs .relative class on parent and next sibling elements) */}

      <div className="relative max-w-6xl mx-auto px-4 sm:px-6">
        <div className="py-8 md:py-10">
          <Heading>特徴</Heading>

          {/* Items */}
          <div className="max-w-sm mx-auto grid gap-6 md:grid-cols-2 lg:grid-cols-3 items-start md:max-w-2xl lg:max-w-none">
            {features.map((f) => (
              <FeatureItem
                key={f.title}
                title={f.title}
                description={f.description}
                icon={f.icon}
              />
            ))}
          </div>
        </div>
      </div>
    </section>
  );
}
